section#no-match {
  padding: 0;

  main.content {
    width: 100%;
    height: 100%;
    position: relative;

    @include lg {
      flex-direction: row-reverse;
      max-height: 100vh;
    }

    .content-image {
      width: 100%;
      height: 100%;
      min-height: 100vh;
      position: relative;

      span {
        font-weight: $fw-black;
        color: var(--bg-color);
        font-size: 3em;
        position: absolute;
        left: 50%;
        top: 40%;
        z-index: 1;
        transform: translateX(-50%);
        opacity: 0.8;

        @include lg {
          top: 2rem;
        }
      }

      img {
        object-fit: cover;
        max-height: 100vh;
        width: 100%;
        object-position: bottom;
      }
    }

    .text-content {
      --curr-p: 2rem;
      --curr-p-y: 2rem;
      height: 100%;
      width: 100%;
      padding: var(--curr-p);
      gap: 1rem;
      min-height: 420px;
      max-height: 420px;

      @include lg {
        position: absolute;
        right: 0;
        bottom: 0;
        height: auto;
        width: auto;
        background-color: var(--bg-color);
        max-width: 750px;
        box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
        z-index: 2;
      }

      @include sm {
        --curr-p: 4rem 3rem;
        --curr-p-y: 4rem;
      }

      header {
        font-size: 2.25rem;
        font-weight: $fw-bold;

        @include md {
          font-size: 2.75rem;
        }
      }

      p {
        font-weight: $fw-light;
        color: var(--secondary-color);
      }

      .button-holder {
        margin-top: 4vh;
      }
    }
  }
}
