section#panel-gallery-view {
  main.content {
    gap: 5rem;
    height: 100%;
    width: 100%;

    .actions-bar {
      width: 100%;
      gap: 1rem;

      .select-wrapper {
        position: relative;
        max-width: 350px;
        width: 100%;
        height: 45px;
      }

      .actions-wrapper {
        gap: 2rem;
      }
    }

    .panel-gallery-images-wrapper {
      width: 100%;
      height: 100%;
      gap: 1.5rem;

      .gallery-content-bar {
        width: 100%;

        span {
          color: var(--secondary-color);
        }
      }

      .gallery-items-wrapper {
        width: 100%;
        height: 100%;

        .gallery-items {
          width: 100%;
          height: 100%;

          display: grid;
          grid-template-columns: repeat(3, minmax(300px, 1fr));
          grid-template-rows: repeat(auto-fill, 1fr);
          gap: 1rem;

          & > .skeleton-gallery-item-tile {
            min-height: 400px;
          }

          & > .gallery-item-tile {
            min-height: 350px;
          }

          @include xl {
            grid-template-columns: repeat(4, 1fr);
          }
        }

        .gallery-groups {
          width: 100%;
          height: 100%;
          display: grid;
          grid-template-columns: 1fr;
          grid-template-rows: repeat(auto-fill, minmax(300px, 450px));
          gap: 3rem;
          grid-auto-flow: dense;

          & > .skeleton-gallery-item-tile {
            min-height: 400px;
          }

          & > .gallery-item-tile {
            min-height: 350px;
          }

          @include sm {
            grid-template-columns: repeat(2, 1fr);
          }

          @include xl {
            grid-template-columns: repeat(3, 1fr);
          }
        }
      }
    }
  }
}
