.gallery-filters {
  width: 100%;
  gap: 3rem;

  @include md {
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
  }

  @include lg {
    align-items: center;
  }

  .gallery-filter-tags {
    width: auto;
    gap: 0.5rem;
    display: flex;
    flex-wrap: wrap;
  }

  .date-sort-wrapper {
    max-width: 350px;
    width: 100%;
    position: relative;
    height: 45px;
  }
}
