section#panel-add-image-view {
  main.content {
    gap: 5rem;

    .content-wrapper {
      gap: 2rem;

      header {
        font-size: 1.75em;
        font-weight: $fw-medium;
      }

      .add-images-dropzone-wrapper {
        width: 100%;
      }

      .select-wrapper {
        width: 100%;
        max-width: 350px;
        min-height: 45px;
        position: relative;
      }

      .images-count {
        margin-top: 7vh;

        span {
          color: var(--secondary-color);
        }
      }

      .uploaded-image-items-wrapper {
        width: 100%;
        display: grid;
        grid-template-columns: repeat(auto-fill, minmax(350px, 1fr));
        grid-template-rows: auto;
        gap: 2rem;
        padding-bottom: 20vh;
      }
    }
  }
}
