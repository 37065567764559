.gallery-item-tile {
  width: 100%;
  height: 100%;
  position: relative;
  cursor: zoom-in;
  user-select: none;

  &:hover {
    .gallery-item-content-overlay {
      opacity: 1;
      visibility: visible;
      pointer-events: all;
    }
  }

  .gallery-item-thumbnail {
    width: 100%;
    height: 100%;
    background-position: center;
    background-size: cover;
    clip-path: inset(0 0 0 0);
    min-height: 300px;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      object-position: center;
    }
  }

  .gallery-item-content-overlay {
    position: absolute;
    inset: 0;
    background-color: rgba(0, 0, 0, 0.2);
    opacity: 0;
    visibility: hidden;
    pointer-events: none;
    transition: 200ms all ease;

    .overlay-content-wrapper {
      padding: 1rem;
      width: 100%;
      gap: 0.5rem;
      flex-wrap: wrap;

      span {
        color: #fff;
        font-weight: $fw-light;
        user-select: none;
      }

      .group-redirect {
        background: none;
        border: none;
        outline: none;

        width: 32px;
        height: 32px;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 0.25rem;
        border-radius: 5px;
        background-color: var(--bg-color);
        cursor: pointer;

        &:hover {
          svg {
            transform: rotate(-45deg) translateX(2px);
            opacity: 1;
          }
        }

        svg {
          opacity: 0.75;
          transition: all ease 200ms;
          transform: rotate(-45deg);

          path {
            stroke-width: 2px;
          }
        }
      }
    }
  }

  .select-overlay {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    transition: 300ms all ease-out;
    cursor: pointer;

    &[data-selected="true"] {
      background-color: rgba(255, 255, 255, 0.6);

      .select::before {
        width: 90%;
        height: 90%;
      }
    }

    .select {
      position: absolute;
      top: 1rem;
      right: 1rem;
      width: 24px;
      height: 24px;
      border: solid 2px var(--bg-color);
      border-radius: 4px;
      padding: 0.2rem;

      &::before {
        content: "";
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        background-color: var(--primary-color);
        transition: 200ms all ease;
        border-radius: inherit;
        width: 0;
        height: 0;
      }
    }
  }
}
