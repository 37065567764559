.input-wrapper {
  width: 100%;
  height: auto;
  gap: 0.5rem;

  label {
    user-select: none;
  }

  input {
    border: 0;
    background: none;
    outline: none;

    width: 100%;
    height: 50px;
    border: solid 2px var(--font-color);
    color: var(--font-color);
    font-weight: $fw-light;
    border-radius: 2px;
    padding: 0.25rem 1rem;
    font-size: 1em;
    overflow: hidden;

    &:placeholder-shown {
      color: var(--secondary-color);
    }
  }

  small.error {
    color: crimson;
    font-weight: $fw-medium;
  }
}
