.header-wrapper {
  max-width: 300px;
  gap: 0.75rem;

  &[data-align="center"] {
    align-items: center;

    header,
    span {
      text-align: center;
    }
  }

  &[data-align="right"] {
    align-items: flex-end;

    header,
    span {
      text-align: right;
    }
  }

  header {
    font-family: "Oswald", sans-serif;
    font-size: 2em;
    font-weight: $fw-semi-bold;
  }

  span {
    font-weight: $fw-light;
    font-size: 0.9em;
  }
}
