:root {
  // Color palette
  --bg-color: #fff;
  --font-color: #000;
  --primary-color: #3e51ff;
  --primary-color-alpha-90: rgba(62, 81, 255, 0.9);
  --primary-color-alpha-80: rgba(62, 81, 255, 0.8);
  --primary-color-alpha-30: rgba(62, 81, 255, 0.3);
  --primary-color-alpha-20: rgba(62, 81, 255, 0.2);
  --primary-color-alpha-10: rgba(62, 81, 255, 0.1);
  --secondary-color: #a3a3a3;

  --site-padding-x: 1rem;
  --site-padding-y: 1rem;
  --site-padding: var(--site-padding-y) var(--site-padding-x);

  --modal-z: 1010;
  --sidebar-z: calc(var(--modal-z) - 5);
  --hamburger-z: calc(var(--sidebar-z) + 1);
  --menu-z: calc(var(--modal-z) - 10);
  --options-box-z: calc(var(--modal-z) - 1);
  --image-view-z: calc(var(--modal-z) - 3);
  --toast-z: calc(var(--modal-z) + 2);
}

$fw-thin: 100;
$fw-extra-light: 200;
$fw-light: 300;
$fw-regular: 400;
$fw-medium: 500;
$fw-semi-bold: 600;
$fw-bold: 700;
$fw-extra-bold: 800;
$fw-black: 900;

// * View.
// Small tablets and large smartphones (landscape view).
$screen-sm-min: 576px;

// Small tablets (portrait view).
$screen-md-min: 768px;

// Tablets and small desktops.
$screen-lg-min: 992px;

// Large tablets and desktops.
$screen-xl-min: 1200px;

@mixin sm {
  @media (min-width: #{$screen-sm-min}) {
    @content;
  }
}

// Medium devices
@mixin md {
  @media (min-width: #{$screen-md-min}) {
    @content;
  }
}

// Large devices
@mixin lg {
  @media (min-width: #{$screen-lg-min}) {
    @content;
  }
}

// Extra large devices
@mixin xl {
  @media (min-width: #{$screen-xl-min}) {
    @content;
  }
}
