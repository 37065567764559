.hamburger {
  $bar-w: 25px;
  cursor: pointer;
  position: absolute;
  right: 0;
  width: $bar-w;
  z-index: var(--hamburger-z);

  &[data-opened="true"] {
    div {
      background-color: var(--font-color) !important;
    }

    &::before {
      position: absolute;
      content: "";
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      width: $bar-w;
      height: $bar-w;
    }
  }

  div {
    width: $bar-w;
    height: 2px;
    background-color: var(--font-color);
    transition: 200ms background-color ease;
    border-radius: 4px;
    position: relative;
  }
}
