section#bday {
  padding: 0;
  cursor: pointer;
  position: relative;

  .confetti {
    max-width: 100%;
    display: block;
    margin: 0 auto;
    user-select: none;
  }

  .wrapper {
    position: absolute;
    left: 0;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
    padding: 36px;
    gap: 3rem;
  }

  .name {
    font-weight: $fw-bold;
    text-transform: uppercase;
    text-align: center;
    color: rebeccapurple;
    font-family: "Righteous", cursive;
    font-size: 5em;
    letter-spacing: 10px;
    text-shadow: 2px 4px 11px rgba(0, 0, 0, 0.28);
  }
}
