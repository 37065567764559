.fullscreen-image-view {
  position: fixed;
  z-index: var(--modal-z);
  inset: 0;
  background-color: rgba(0, 0, 0, 0.9);

  .content {
    margin: 1rem;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: grab;

    @include md {
      margin: 10vh;
    }

    @include lg {
      margin: 20%;
      cursor: initial;
    }

    .image-wrapper {
      width: 100%;
      min-width: 100%;
      height: 100%;
      min-height: 200px;
      max-height: 700px;
      background-position: center;
      background-size: cover;
      clip-path: inset(0 0 0 0);

      img {
        width: 100%;
        height: 100%;
        object-position: center;
        object-fit: cover;
        max-height: 700px;
      }
    }

    .image-info {
      padding: 1rem;
      width: 100%;
      gap: 1rem;
      flex-direction: column;
      align-items: flex-start;
      justify-content: flex-start;

      @include md {
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
      }

      span.date {
        color: var(--bg-color);
        font-weight: $fw-light;
      }

      .tags {
        flex-wrap: wrap;
        gap: 0.5rem;
      }
    }
  }

  .paginator {
    position: absolute;
    left: 0.5rem;
    bottom: 2rem;
    transform: translateY(-50%) rotate(90deg);
    cursor: pointer;
    z-index: 2;
    display: none;

    @include lg {
      top: 50%;
      display: flex;
      justify-content: center;
      align-items: center;
    }

    &.right {
      left: initial;
      right: 0.5rem;
      transform: translateY(-50%) rotate(-90deg);
    }

    svg {
      path {
        stroke: #fff;
        stroke-width: 2px;
      }
    }
  }
}
