.gallery-group-item-tile {
  width: 100%;
  height: 100%;
  position: relative;
  cursor: pointer;
  user-select: none;

  &[data-panel="false"]:hover {
    .images-stack-thumbnail {
      clip-path: none;

      .cover-image {
        transform: scale(1.02);
      }

      .stack-image {
        &[data-index="0"] {
          transform: translate3d(5%, -5%, 0) rotate3d(0, 0, 1, 5deg);
        }

        &[data-index="1"] {
          transform: translate3d(-5%, 0, 0) rotate3d(0, 0, 1, -5deg);
        }
      }
    }
  }

  .images-stack-thumbnail {
    width: 100%;
    height: 100%;
    background-position: center;
    background-size: cover;
    clip-path: inset(0 0 0 0);
    min-height: 300px;
    position: relative;
    isolation: isolate;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      object-position: center;
    }

    .cover-image,
    .stack-image {
      transition: 300ms all ease-out;
      border: solid 1px var(--bg-color);
    }

    .cover-image {
      z-index: 2;
    }

    .stack-image {
      position: absolute;
      left: 0;
      top: 0;
      z-index: -1;
      transform-style: preserve-3d;
      transform: translate3d(0, 0, 0) rotate3d(0, 0, 0, 0deg);
    }
  }

  .group-item-text-content {
    width: 100%;
    padding: 1rem 0;
    color: var(--font-color);

    span.group-name {
      font-weight: $fw-medium;
      font-size: 1em;
    }

    span.group-localization {
      font-weight: $fw-light;
      color: var(--primary-color);
    }
  }
}
