.offline-container {
  width: 100%;
  height: 100%;
  min-height: 70vh;

  .content {
    gap: 1rem;
    margin-top: 10vh;

    header {
      font-size: 1.75em;
      font-weight: $fw-semi-bold;
      text-align: center;

      @include md {
        font-size: 2.25em;
      }
    }

    p {
      color: var(--secondary-color);
      font-weight: $fw-light;
      text-align: center;
    }
  }
}
