.scroll-to-top {
  position: fixed;
  right: 1rem;
  bottom: 1rem;
  padding: 0.5rem;
  background-color: var(--primary-color);
  border-radius: 4px;
  width: 52px;
  height: 52px;
  cursor: pointer;
  transition: 200ms all ease;
  border: solid 2px var(--primary-color);

  &[hidden="true"] {
    display: none;
  }

  @include lg {
    right: 2rem;
    bottom: 2rem;
  }

  &:hover {
    background-color: transparent;

    .icon path {
      stroke: var(--primary-color);
    }
  }

  .icon {
    transform: rotate(180deg);

    path {
      stroke: #fff;
      stroke-width: 2.5px;
      transition: 200ms all ease;
    }
  }
}
