.gallery-content-wrapper {
  height: 100%;
  width: 100%;
  gap: 2rem;

  .gallery-content-bar {
    width: 100%;

    span {
      color: var(--secondary-color);
    }
  }

  .gallery-wrapper {
    width: 100%;
    height: 100%;
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: repeat(auto-fill, minmax(300px, 450px));
    gap: 1rem;
    grid-auto-flow: dense;

    &[data-safari="true"] {
      grid-template-rows: auto;
    }

    & > .skeleton-gallery-item-tile {
      min-height: 400px;
    }

    & > .gallery-item-tile {
      min-height: 450px;
    }

    @include sm {
      grid-template-columns: repeat(2, 1fr);
    }

    @include lg {
      grid-template-columns: repeat(3, 1fr);
    }

    @include xl {
      grid-template-columns: repeat(4, 1fr);
    }
  }
}
