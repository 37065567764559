.offer-tile-item {
  width: 100%;
  min-height: 85vh;
  height: auto;
  border-radius: 10px;
  background-color: var(--bg-color);
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  gap: 1rem;
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 250px auto;
  max-width: 1024px;
  max-height: var(--max-height);

  --max-height: 1024px;
  --min-height: 85vh;
  --rows: 250px auto;

  @include lg {
    min-height: 50vh;
    grid-template-columns: 40% auto;
    grid-template-rows: 1fr;

    --min-height: 50vh;
    --rows: 1fr;
  }

  .offer-tile-image {
    width: 100%;
    height: 100%;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
    border-top-left-radius: inherit;
    border-top-right-radius: inherit;

    @include lg {
      border-top-right-radius: initial;
      border-bottom-left-radius: inherit;
    }
  }

  .offer-tile-content {
    width: 100%;
    height: auto;
    padding: 1rem 1rem 2rem;
    gap: 2rem;

    @include lg {
      padding: 1.5rem;
    }

    .offer-tile-header-content {
      gap: 0.5rem;

      header {
        font-size: 2em;
        font-weight: $fw-bold;
        color: var(--primary-color);
      }

      small {
        overflow: hidden;

        small {
          display: block;
          font-weight: $fw-light;
          color: var(--font-color);
        }
      }
    }
  }

  .motion-p {
    overflow: hidden;

    .offer-tile-description {
      display: block;
      font-size: 0.9em;
      color: var(--font-color);
    }
  }

  .offer-tile-works-content {
    gap: 0.5rem;
    width: 100%;

    span {
      overflow: hidden;

      span.offer-tile-works-title {
        display: block;
        font-weight: $fw-medium;
      }
    }

    .offer-tile-works {
      width: 100%;
      gap: 0.5rem;
      display: flex;
      flex-wrap: wrap;

      .motion-work {
        overflow: hidden;
        .offer-tile-work {
          gap: 0.25rem;

          span {
            font-size: 0.9em;
          }

          svg {
            min-width: 24px;
            min-height: 24px;
            max-width: 24px;
            max-height: 24px;

            path {
              fill: var(--primary-color);
            }
          }
        }
      }
    }
  }

  .button-wrapper {
    @include lg {
      margin-top: auto;
      margin-left: auto;
    }
  }
}
