@import url("https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&family=Oswald:wght@200;300;400;500;600;700&display=swap");

@import "utils";
@import "config";

@import "layouts/navigation";
@import "layouts/footer";
@import "layouts/panel-layout";
@import "layouts/panel-sidebar";
@import "layouts/panel-topbar";

@import "views/happy-bday";
@import "views/landing";
@import "views/contact";
@import "views/offer";
@import "views/gallery";
@import "views/ggroup";
@import "views/groups-gallery";
@import "views/no-match";
@import "views/panel/login";
@import "views/panel/dashboard";
@import "views/panel/panel-gallery";
@import "views/panel/panel-gallery-group";
@import "views/panel/add-images";

@import "components/logo";
@import "components/hamburger";
@import "components/button";
@import "components/submit-button";
@import "components/select";
@import "components/input";
@import "components/password-input";
@import "components/loader";
@import "components/small-loader";
@import "components/header";
@import "components/gallery-tag";
@import "components/gallery-item-tile";
@import "components/skeleton-gallery-item-tile";
@import "components/gallery-group-item-tile";
@import "components/scroll-to-top";
@import "components/review-item";
@import "components/landing-offer-item";
@import "components/landing-offer-image";
@import "components/dot-pagination";
@import "components/offer-tile-item";
@import "components/contact-us-block";
@import "components/fullscreen-image-view";
@import "components/gallery-wrapper";
@import "components/group-gallery-wrapper";
@import "components/gallery-filters";
@import "components/offline";
@import "components/login-form";
@import "components/panel-user";
@import "components/checkbox";
@import "components/comeback-button";
@import "components/image-upload-box";
@import "components/add-image-item";
@import "components/panel-actions-bar";
@import "components/switch";
@import "components/modal";
@import "components/cookies-modal";

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Inter", sans-serif;
}

body,
html {
  min-height: 100vh;
  overflow-x: clip;
  background-color: var(--background-color);
  scroll-behavior: smooth;

  @include sm {
    --site-padding: 2rem 1rem;
  }

  @include md {
    --site-padding: 2.5rem 1.5rem;
    --site-padding-x: 1.5rem;
  }

  @include lg {
    --site-padding: 3.5rem 2rem;
    --site-padding-x: 2rem;
  }

  @include xl {
    --site-padding: 3.5rem 3rem;
    --site-padding-x: 3rem;
  }
}

section {
  min-height: 100vh;
  overflow: clip;
  padding: var(--site-padding);
  padding-top: 4.5rem;

  @include md {
    padding: 2.5rem 1.5rem;
    padding-top: 4.5rem;
  }

  @include lg {
    padding: 3.5rem 2rem;
    padding-top: 4.5rem;
  }

  @include xl {
    padding: 3.5rem 3rem;
    padding-top: 4.5rem;
  }
}

a {
  text-decoration: none;
  color: var(--primary-color);
}

form {
  max-width: 400px;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  gap: 1rem;
}

img {
  user-select: none;
}
