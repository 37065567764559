section#offer {
  position: relative;
  isolation: isolate;

  .dots {
    position: absolute;
    right: -50%;
    top: 5vh;
    z-index: -1;
    pointer-events: none;

    @include md {
      right: 10vw;
      top: 20vh;
    }
  }

  main.content {
    width: 100%;
    height: 100%;
    margin-top: 5vh;
    gap: 10rem;

    .header-content {
      width: 100%;
      gap: 2rem;

      header {
        font-size: 3em;
        font-weight: $fw-bold;
        font-family: "Oswald", sans-serif;
        line-height: 1em;
        max-width: 450px;

        span {
          color: var(--primary-color);
          font-family: "Oswald", sans-serif;
        }
      }

      p {
        font-weight: $fw-light;
        max-width: 500px;
        color: var(--font-color);
      }
    }

    .offer-content {
      width: 100%;
      height: auto;
      gap: 3rem;

      header {
        font-size: 1.5em;
        font-weight: $fw-medium;

        @include lg {
          font-size: 2em;
        }

        span {
          color: var(--primary-color);
        }
      }

      .offer-tiles {
        width: 100%;
        gap: 7.5rem;
      }
    }
  }
}
