.modal {
  position: fixed;
  z-index: var(--modal-z);
  background-color: var(--bg-color);
  width: 90%;
  min-height: 200px;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  max-width: 600px;
  gap: 1rem;

  .modal-body {
    width: 100%;
    height: 100%;
    padding: 0 1.5rem 1.5rem;

    @include md {
      padding: 0 2rem 2rem;
    }

    @include lg {
      padding: 0 2rem 3rem;
    }
  }

  .modal-top-bar {
    padding: 1.5rem 1rem 0;

    svg path {
      fill: var(--secondary-color);
    }
  }
}

.modal-overlay {
  position: fixed;
  z-index: calc(var(--modal-z) - 1);
  inset: 0;
  background-color: rgba(0, 0, 0, 0.1);
}
