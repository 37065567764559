.contact-us-block {
  gap: 2rem;
  margin: 10vh 0 15vh;

  p {
    color: #a3a3a3;
    font-size: 1em;
    font-weight: $fw-light;

    @include md {
      max-width: 350px;
    }
  }

  span {
    font-size: 3rem;
    font-weight: $fw-black;
    color: var(--primary-color);

    @include md {
      font-size: 4.5rem;
    }

    @include lg {
      font-size: 6rem;
      max-width: 70vw;
    }

    span {
      color: transparent;
      -webkit-text-stroke: 2px var(--primary-color);
    }
  }
}
