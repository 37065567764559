section#contact-view {
  position: relative;
  isolation: isolate;

  .dots {
    z-index: -1;
    position: absolute;
    left: 1rem;
    top: 200px;
    transform: translateY(-10%);
    width: calc(100% - 2rem);

    @include sm {
      width: auto;
    }

    @include md {
      left: 15vw;
    }

    svg {
      height: 100%;

      #dots-3,
      #dots-4 {
        display: none;
        visibility: hidden;
        opacity: 0;
      }
    }
  }

  main.content {
    width: 100%;
    height: 100%;
    gap: 10rem;
    margin-top: 5rem;
    padding-bottom: 10vh;

    @include sm {
      padding-left: 5vw;
    }

    @include lg {
      margin-top: 10rem;
      gap: 5rem;
    }

    .header-content {
      width: 100%;
      gap: 2rem;

      header {
        font-size: 3em;
        font-weight: $fw-bold;
        font-family: "Oswald", sans-serif;
        line-height: 1em;

        span {
          color: var(--primary-color);
          font-family: "Oswald", sans-serif;
        }
      }

      p {
        font-weight: $fw-light;
        max-width: 500px;
      }
    }

    .contact-info {
      gap: 5rem;
      width: 100%;

      @include lg {
        flex-direction: row-reverse;
      }

      iframe {
        width: 100%;

        @include lg {
          height: 600px;
        }
      }
    }

    .contact-boxes {
      width: 100%;
      gap: 3rem;

      @include lg {
        width: auto;
      }

      @include md {
        align-items: flex-end;
        padding: 0 2rem;
      }

      .box {
        display: flex;
        flex-direction: column;
        gap: 1rem;
        width: 200px;

        small {
          text-transform: uppercase;
          color: var(--primary-color);
          overflow: hidden;

          small {
            display: block;
          }
        }

        span {
          overflow: hidden;

          a {
            font-size: 1.1em;
            color: var(--font-color);
            display: block;
          }
        }
      }
    }
  }
}
