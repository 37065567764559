.group-gallery-wrapper {
  width: 100%;
  height: 100%;
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: repeat(auto-fill, minmax(300px, 450px));
  gap: 3rem;
  grid-auto-flow: dense;

  & > .skeleton-gallery-item-tile {
    min-height: 400px;
  }

  & > .gallery-item-tile {
    min-height: 350px;
  }

  @include sm {
    grid-template-columns: repeat(2, 1fr);
  }

  @include lg {
    grid-template-columns: repeat(3, 1fr);
  }

  @include xl {
    grid-template-columns: repeat(4, 1fr);
  }
}
