nav#panel-sidebar {
  width: 100%;
  max-width: 250px;
  min-width: 200px;
  position: sticky;
  padding-top: 10vh;

  .sidebar-content {
    width: 100%;
    padding: 2rem 0;
    border-right: solid 1px var(--secondary-color);
    gap: 5rem;

    header.greeting {
      font-size: 1.5em;
      font-weight: $fw-medium;
    }

    ul.sidebar-navlinks {
      gap: 1rem;
      width: 100%;
      list-style: none;

      li.log-out {
        color: var(--font-color);
        position: relative;
        transition: 200ms all ease;
        padding: 1rem 1.5rem;
        left: calc(1rem + 24px);
        font-weight: $fw-regular;
        cursor: pointer;
      }

      a {
        color: var(--font-color);
        position: relative;
        transition: 200ms all ease;
        padding: 1rem 1.5rem;

        &::before {
          content: "";
          position: absolute;
          right: 0;
          top: 0;
          height: 100%;
          width: 0;
          background-color: var(--primary-color);
          border-bottom-left-radius: 40px;
          border-top-left-radius: 40px;
          z-index: -1;
          transition: 200ms all ease;
        }

        &[data-active="true"] {
          color: #fff;

          &::before {
            width: 100%;
          }

          svg {
            opacity: 1;
          }
        }

        li {
          width: 100%;
          cursor: pointer;
          display: flex;
          align-items: center;
          gap: 1rem;

          svg {
            transition: 200ms all ease;
            opacity: 0;

            path {
              stroke-width: 2px;
            }

            path,
            circle {
              transition: 200ms all ease;
              stroke: #fff;
            }

            &[data-id="1"],
            &[data-id="2"] {
              path,
              circle {
                fill: #fff;
                stroke: transparent;
              }
            }
          }

          a {
          }
        }
      }
    }
  }
}
