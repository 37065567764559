.landing-offer-image {
  width: 100%;
  height: 100%;
  position: absolute;
  inset: 0;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  border-radius: inherit;
}
