.landing-offer-item {
  padding: 5vh var(--site-padding-x);
  gap: 1rem;
  transition: 200ms all ease;

  @include md {
    padding: 10vh var(--site-padding-x);
  }

  span {
    font-size: 2em;
    font-weight: $fw-bold;
    color: lightgray;
  }

  p {
    font-size: 1.25em;
    font-weight: $fw-light;
    color: lightgray;
  }
}
