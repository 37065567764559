#panel-gallery-group-view {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  gap: 5rem;

  main.content {
    gap: 5rem;
    height: 100%;
    width: 100%;

    .actions-bar {
      width: 100%;
      gap: 1rem;

      .select-wrapper {
        position: relative;
        max-width: 350px;
        width: 100%;
        height: 45px;
      }

      .actions-wrapper {
        gap: 2rem;
      }
    }

    .group-info {
      width: 100%;
      height: auto;
      gap: 2rem;

      .skeleton-image {
        max-width: 256px;
        max-height: 256px;
        height: 100%;
        width: 100%;
        aspect-ratio: 1/1;
      }

      .thumbnail {
        width: 100%;
        height: 100%;
        background-position: center;
        background-size: cover;
        clip-path: inset(0 0 0 0);
        max-height: 256px;
        max-width: 256px;
        aspect-ratio: 1/1;

        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
          object-position: center;
          aspect-ratio: 1/1;
        }
      }

      .text-content {
        gap: 1rem;

        .skeleton-p {
          gap: 0.35rem;
        }

        header {
          font-size: 2em;
          font-weight: $fw-medium;
        }

        p {
          font-weight: $fw-light;
          color: var(--secondary-color);
          max-width: 600px;
        }

        span {
          color: var(--primary-color);
          display: flex;
          align-items: center;
          gap: 0.25rem;

          svg path {
            fill: var(--primary-color);
          }
        }
      }
    }

    .panel-gallery-images-wrapper {
      width: 100%;
      height: 100%;
      gap: 1.5rem;

      .gallery-content-bar {
        width: 100%;

        span {
          color: var(--secondary-color);
        }
      }

      .group-images {
        width: 100%;
        height: 100%;

        display: grid;
        grid-template-columns: repeat(3, minmax(300px, 1fr));
        grid-template-rows: repeat(auto-fill, 1fr);
        gap: 1rem;

        & > .skeleton-gallery-item-tile {
          min-height: 400px;
        }

        & > .gallery-item-tile {
          min-height: 350px;
        }

        @include xl {
          grid-template-columns: repeat(4, 1fr);
        }
      }
    }
  }
}
