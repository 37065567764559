footer {
  width: 100vw;
  min-height: 40vh;
  padding: 2rem var(--site-padding-x);
  padding-bottom: 4rem;
  display: flex;
  align-items: flex-start;
  gap: 5rem;
  justify-content: flex-start;
  flex-direction: column;

  @include lg {
    flex-direction: row;
    justify-content: space-between;
    padding-bottom: 2rem;
  }

  .logo-column {
    gap: 1.25rem;

    .logo {
      span {
        font-size: 2em;
        text-transform: uppercase;
        font-weight: $fw-light;

        &:nth-child(2) {
          color: var(--font-color);
          font-weight: $fw-medium;
        }
      }
    }

    .copyright-info {
      gap: 1rem;

      span {
        font-size: 0.9em;
        font-weight: $fw-extra-light;
      }

      a {
        font-weight: $fw-regular;
      }

      .copyright {
        gap: 0.25rem;
      }
    }
  }

  .links-column-wrapper {
    display: grid;
    gap: 2rem;

    @include md {
      grid-template-columns: 1fr 1fr;
      grid-template-rows: 1fr 1fr;
    }

    @include lg {
      grid-template-columns: repeat(3, auto);
      grid-template-rows: 1fr;
      gap: 5rem;
      margin: 0 auto;
    }

    a {
      color: var(--font-color);
    }

    .links-column {
      gap: 0.75rem;
      font-size: 0.9em;

      .footer-links {
        gap: 0.5rem;

        .footer-link {
          font-weight: $fw-extra-light;
        }
      }
    }
  }
}
