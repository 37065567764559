.logo-wrapper {
  &[data-size="small"] {
    .logo span {
      font-size: 1em;

      @include lg {
        font-size: 1.2em;
      }
    }
  }

  .logo {
    span {
      font-size: 1.3em;
      text-transform: uppercase;
      color: var(--font-color);

      @include lg {
        font-size: 1.5em;
      }

      &:nth-child(2) {
        font-weight: $fw-semi-bold;
      }
    }
  }
}
