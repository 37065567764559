section#login-view {
  display: grid;
  place-items: center;
  padding: 1rem;

  main.content {
    width: 100%;
    height: 100%;
    position: relative;

    .login-box {
      gap: 1rem;

      header {
        font-size: 1.5em;
        font-weight: $fw-medium;
      }

      .error {
        color: crimson;
        font-size: 1em;
      }
    }
  }
}
