.switch {
  width: 40px;
  height: 20px;
  background-color: #d3d3d3;
  justify-content: flex-start;
  border-radius: 50px;
  cursor: pointer;
  user-select: none;

  &[data-disabled="true"] {
    opacity: 0.5;
  }

  &[data-is-on="true"] {
    justify-content: flex-end;

    .handle {
      background-color: var(--primary-color);
    }
  }

  .handle {
    width: 20px;
    height: 20px;
    border-radius: 50%;
    background-color: var(--secondary-color);
  }
}
