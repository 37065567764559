#cookies-modal {
  bottom: 1.5rem;
  top: initial;
  left: 1.5rem;
  transform: none;

  .cookies-modal-wrapper {
    width: 100%;
    height: 100%;
    position: relative;
  }

  .cookies-modal-content {
    position: relative;
    gap: 3rem;
    width: 100%;

    header {
      font-size: 1.4em;
      font-weight: $fw-semi-bold;
    }

    p {
      color: var(--secondary-color);
      font-weight: $fw-light;
      font-size: 0.9em;
      max-width: 450px;
    }

    .cookies-modal-buttons {
      margin-top: 2rem;
      gap: 0.5rem;

      @include sm {
        flex-direction: row;
      }
    }

    &.cookies-modal-manager {
      .cookies-manager {
        gap: 2rem;
        margin-bottom: auto;

        .cookies-row {
          gap: 1rem;

          .row-top-bar {
            span {
              font-weight: $fw-medium;
            }
          }
        }
      }
    }
  }
}
