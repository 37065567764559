.gallery-tag {
  padding: 0.35rem 0.625rem;
  border-radius: 20px;
  background-color: #d3d3d3;
  border: solid 2px #a3a3a3;
  cursor: pointer;
  transition: 200ms all ease;

  &[data-active="true"] {
    background-color: var(--primary-color-alpha-30);
    border-color: var(--primary-color);

    span {
      color: var(--primary-color);
    }
  }

  &[data-size="small"] {
    span {
      font-size: 0.8em;
    }
  }

  span {
    text-transform: capitalize;
    font-weight: $fw-medium;
    color: #a3a3a3;
    user-select: none;
    transition: 200ms all ease;
  }
}
