section#gallery-group {
  main.content {
    position: relative;
    width: 100%;
    height: 100%;
    margin-top: 5rem;
    padding-bottom: 15vh;
    gap: 5rem;
    isolation: isolate;

    @include sm {
      padding: 0 5vw 15vh;
    }

    @include lg {
      margin-top: 5rem;
    }

    .main-site-text {
      box-shadow: none;
      color: var(--primary-color);
      font-size: 10rem;
      position: absolute;
      z-index: -1;
      left: 10%;
      top: -5%;
      font-weight: $fw-bold;
      user-select: none;
      pointer-events: none;

      @include md {
        font-size: 15rem;
      }

      @include lg {
        font-size: 20rem;
        left: 40%;
        top: -10%;
      }
    }

    .header-content {
      width: 100%;
      gap: 2rem;

      header {
        font-size: 2em;
        font-weight: $fw-medium;

        @include md {
          font-size: 3em;
        }
      }

      p {
        width: 100%;
        color: var(--secondary-color);
        font-weight: $fw-light;

        @include md {
          width: 65%;
        }
      }

      .localization {
        gap: 0.5rem;

        span {
          font-size: 1.2em;
          font-weight: $fw-medium;
          color: var(--primary-color);
        }
      }
    }

    .groups-btn-wrapper {
      width: 100%;
      display: grid;
      place-items: center;
      padding: 10vh 0 5vh;
    }
  }
}
