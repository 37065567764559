.panel-user {
  $size: 48px;
  height: $size;
  width: $size;
  border-radius: 50%;
  background-color: #ebebeb;
  border: solid 1px var(--font-color);
  padding: 0.25rem;
  display: flex;
  align-items: center;
  justify-content: center;
}
