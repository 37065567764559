.select {
  position: absolute;
  width: 100%;
  height: auto;
  isolation: isolate;

  .select-overlay {
    position: fixed;
    inset: 0;
    z-index: 1;
    display: none;

    &[data-active="true"] {
      display: block;
    }
  }

  button.main-face {
    background: none;
    border: none;
    outline: none;
    cursor: pointer;

    border: solid 1.5px var(--font-color);

    width: 100%;
    height: 45px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0.5rem 0.75rem;
    text-transform: uppercase;
    z-index: 3;

    span {
      color: var(--font-color);
    }

    svg path {
      stroke-width: 2px;
      stroke: var(--font-color);
    }
  }

  .select-options {
    border: solid 1px var(--font-color);
    border-top-color: transparent;
    width: 100%;
    background-color: var(--bg-color);
    position: relative;
    top: -1px;
    z-index: 2;

    .select-option {
      width: 100%;
      display: flex;
      padding: 0.5rem 0.75rem;
      transition: 200ms all ease;

      &[data-disabled="true"] {
        opacity: 0.5;
        background-color: #d3d3d3;
      }

      &[data-active="true"] {
        background-color: var(--primary-color-alpha-30);
      }

      &:hover:not([data-disabled="true"]):not([data-active="true"]) {
        cursor: pointer;
        background-color: var(--primary-color-alpha-20);
      }

      input {
        display: none;
      }

      label {
        font-size: 0.9em;

        &:first-letter {
          text-transform: capitalize;
        }
      }
    }
  }
}
