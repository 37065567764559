.panel-actions-bar {
  position: fixed;
  left: 50%;
  bottom: 2rem;
  transform: translateX(-50%);
  border-radius: 30px;
  background-color: #d3d3d3;
  gap: 1rem;
  padding: 0.5rem 0.75rem;

  .action {
    border-radius: 20px;
    padding: 1rem 1.5rem;
    cursor: pointer;
    gap: 0.5rem;
    transition: 200ms all ease;

    &:hover {
      background-color: rgba(0, 0, 0, 0.1);
    }

    &[data-id="delete"] {
      span {
        color: crimson;
      }

      svg path {
        fill: crimson;
      }
    }

    span {
      color: #505050;
    }

    svg path {
      fill: #505050;
    }
  }
}
