.panel-layout {
  width: 100%;
  height: 100%;
  min-height: 100vh;

  padding: 4rem 5vw 4rem 10vw;
  gap: 3rem;

  .panel-content {
    width: 100%;
    height: 100%;
    gap: 4.5rem;

    .panel-content-wrapper {
      width: 100%;

      section.panel-view {
        padding: 0;
        min-height: initial;
      }
    }
  }
}
