.submit-button-wrapper {
  width: 100%;
  height: 50px;
  margin-top: 2rem;

  button {
    border: none;
    outline: none;
    background: none;
    background-color: var(--primary-color-alpha-90);
    width: 100%;
    height: 100%;
    transition: 200ms all ease;
    border-radius: 2px;

    &:hover {
      cursor: pointer;
      background-color: var(--primary-color);
    }

    span {
      color: #fff;
      font-size: 1.2em;
    }
  }
}
