#navigation {
  width: 100%;
  position: fixed;
  left: 0;
  top: 0;
  inset: 0 0 auto 0;
  padding: 1.5rem 1.25rem;
  z-index: var(--menu-z);
  transition: 200ms all ease;

  &[data-invert="true"] {
    .nav-bar {
      .phone-number {
        color: var(--bg-color);
      }

      .logo {
        span:nth-child(2) {
          color: var(--bg-color);
        }
      }

      .hamburger div {
        background-color: var(--bg-color);
      }
    }
  }

  &:not([data-state="0"]):not([data-state="1"]) {
    background-color: var(--bg-color);
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
    padding-top: 1.25rem;
    padding-bottom: 1.25rem;

    @include lg {
      padding-top: 1.5rem;
      padding-bottom: 1.5rem;
    }

    .nav-bar {
      .phone-number {
        color: var(--font-color);
      }

      .logo {
        span:nth-child(2) {
          color: var(--font-color);
        }
      }

      .hamburger div {
        background-color: var(--font-color);
      }
    }
  }

  &[data-state="1"] {
    opacity: 0;
    pointer-events: none;
    visibility: hidden;
  }

  @include md {
    padding: 2rem var(--site-padding);
  }

  .nav-bar {
    position: relative;

    .phone-number {
      display: none;
      color: var(--font-color);
      font-size: 1.1em;
      font-family: "Oswald", sans-serif;
      font-weight: $fw-light;

      @include md {
        display: block;
      }
    }

    .logo {
      @include md {
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
      }
    }
  }

  .side-menu-image {
    position: fixed;
    left: 0;
    top: 0;
    z-index: calc(var(--sidebar-z) - 1);
    width: 70%;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.3);

    @include md {
      background-image: url("../../../assets/webp/menu-bg.webp");
      background-size: cover;
      background-repeat: no-repeat;
      background-position: center center;
    }
  }

  aside.sidebar {
    position: fixed;
    right: 0;
    top: 0;
    z-index: var(--sidebar-z);
    min-width: 320px;
    width: 50%;
    max-width: 600px;
    height: 100vh;
    background-color: var(--bg-color);
    gap: 4rem;
    padding: 8rem 2rem 2rem;

    @include lg {
      padding: 8rem 3rem 2rem;
    }

    .logo {
      span {
        text-transform: uppercase;
        color: var(--primary-color);
        font-weight: $fw-bold;
      }
    }

    .nav-links {
      list-style: none;
      gap: 1rem;
      width: 100%;

      &:hover li:not(:hover) {
        filter: blur(3px);
      }

      li {
        --i: 0;
        font-size: 2em;
        transition: 300ms all ease;
        transition-delay: var(--i);
        color: var(--font-color);
        font-weight: $fw-light;

        @include md {
          font-size: 2.25em;
        }
      }
    }

    .menu-phone-number {
      margin-top: auto;
      font-size: 1em;
      font-weight: $fw-light;
      color: var(--font-color);
    }
  }
}
