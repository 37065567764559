.add-image-item {
  width: 100%;
  height: 100%;
  gap: 1rem;
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: auto auto;

  .image {
    width: 100%;
    height: 100%;

    img {
      object-fit: cover;
      width: 100%;
      height: 100%;
    }
  }

  .add-image-item-action-bar {
    gap: 1rem;

    .remove-icon-wrapper {
      cursor: pointer;
      padding: 0.25rem 1rem 0.25rem 0.5rem;
      gap: 0.5rem;
      border-radius: 10px;
      width: fit-content;
      transition: 200ms all ease;

      &:hover {
        background-color: rgba(0, 0, 0, 0.1);
      }

      span {
        color: var(--secondary-color);
        font-weight: $fw-semi-bold;
        font-size: 1.1em;
      }

      svg path {
        fill: var(--secondary-color);
      }
    }

    .tags {
      flex-wrap: wrap;
      gap: 0.25rem;
    }

    .tags-select-wrapper {
      width: 100%;
      position: relative;
      min-height: 45px;
    }
  }
}
