.upload-image-wrapper {
  width: 100%;

  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 0.5rem;

  @include md {
    flex-direction: row;
    align-items: flex-end;
    justify-content: space-between;
    gap: 1.5rem;
  }

  .info {
    gap: 1rem;
  }

  .image-wrapper {
    width: 100%;
    gap: 0.5rem;

    small.image-error {
      color: crimson;
      font-weight: $fw-light;
    }

    .uploaded-images {
      width: 100%;
      height: 100%;
      display: flex;
      flex-wrap: wrap;

      img {
        max-width: 150px;
        width: 100%;

        @include md {
          max-width: 250px;
        }
      }
    }
  }

  input {
    display: none;
  }
}
