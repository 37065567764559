section#gallery-view {
  position: relative;
  isolation: isolate;

  .dots {
    z-index: -1;
    position: absolute;
    left: 1rem;
    top: 50vh;
    transform: translateY(-10%);
    width: calc(100% - 2rem);

    @include sm {
      width: auto;
    }

    @include md {
      left: 15vw;
      top: 40vh;
    }

    svg {
      height: 100%;

      #dots-3,
      #dots-4 {
        display: none;
        visibility: hidden;
        opacity: 0;
      }
    }
  }

  main.content {
    width: 100%;
    height: 100%;
    gap: 5rem;
    margin-top: 5rem;
    padding-bottom: 15vh;

    @include sm {
      padding: 0 5vw 15vh;
    }

    @include lg {
      margin-top: 10rem;
      gap: 5rem;
    }

    .header-content {
      width: 100%;
      gap: 2rem;

      header {
        font-size: 3em;
        font-weight: $fw-bold;
        font-family: "Oswald", sans-serif;
        line-height: 1em;

        span {
          color: var(--primary-color);
          font-family: "Oswald", sans-serif;
        }
      }

      p {
        font-weight: $fw-light;
        max-width: 500px;
      }
    }
  }
}
