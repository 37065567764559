.button-holder {
  max-width: 300px;

  * {
    user-select: none;
  }

  &[data-is-panel="true"] .btn {
    border-radius: 5px;

    span {
      font-weight: $fw-medium;
    }
  }

  &[data-filled="true"] {
    .btn {
      background-color: var(--font-color);

      span {
        color: var(--bg-color) !important;
      }
    }

    &[data-color="white"] .btn {
      span {
        color: var(--font-color) !important;
      }

      background-color: var(--bg-color);
    }

    &[data-color="red"] .btn {
      border-color: crimson;

      span {
        color: var(--bg-color) !important;
      }

      background-color: crimson;
    }

    &[data-color="primary"] .btn {
      border-color: var(--primary-color);

      span {
        color: var(--bg-color) !important;
      }

      background-color: var(--primary-color);
    }
  }

  &[data-color="white"] {
    .btn {
      border-color: var(--bg-color);

      span {
        color: var(--bg-color) !important;
      }

      &:hover {
        span {
          color: var(--font-color) !important;
        }

        background-color: var(--bg-color);
      }
    }
  }

  .btn {
    border: none;
    background: none;
    outline: none;

    display: flex;
    align-items: center;
    justify-content: center;
    gap: 1rem;

    min-width: 140px;
    cursor: pointer;

    border: solid 1px var(--font-color);
    padding: 0.75rem 2rem;
    transition: 200ms all ease;
    text-decoration: none;

    &:hover {
      background-color: var(--font-color);
      transform: scale(0.98);

      span {
        color: var(--bg-color) !important;
      }
    }

    &[type="submit"] {
      width: 100%;
      background-color: var(--font-color);
      font-weight: 400;

      span {
        color: var(--bg-color);
      }
    }

    span {
      font-weight: 300;
      text-transform: uppercase;
      color: var(--font-color);
      transition: 200ms all ease;
      font-size: 14px;
    }

    &:disabled {
      opacity: 0.5;
    }
  }
}
