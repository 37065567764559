.checkbox {
  gap: 0.25rem;
  cursor: pointer;
  isolation: isolate;

  &[data-checked="true"] {
    input::before {
      width: 70% !important;
      height: 70% !important;
    }
  }

  .checkbox-wrapper {
    gap: 1rem;

    input {
      appearance: none;
      width: 24px;
      height: 24px;
      cursor: pointer;

      border: solid 2px var(--font-color);
      border-radius: 3px;
      position: relative;

      &::before {
        content: "";
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        width: 0;
        height: 0;
        background-color: var(--primary-color);
        transition: 200ms all ease;
      }
    }

    label {
      font-size: 1.1rem;
      user-select: none;
      z-index: -1;
    }
  }
}
