.comeback {
  cursor: pointer;
  gap: 0.25rem;
  font-weight: $fw-medium;
  color: var(--secondary-color);

  svg {
    transform: rotate(90deg);

    path {
      stroke: var(--secondary-color);
      stroke-width: 2px;
    }
  }
}
