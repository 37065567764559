.review-item {
  min-width: 320px;
  max-width: 320px;
  height: 200px;
  border-radius: 5px;
  border: solid 2px lightgray;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
  padding: 1rem;
  gap: 0.5rem;
  background-color: var(--bg-color);

  .review-content {
    font-weight: $fw-light;
    font-size: 0.9em;
    margin-top: 1rem;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    display: -webkit-box;
    overflow: hidden;
    width: 100%;
  }
}
