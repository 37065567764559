.dot-pagination {
  gap: 0.25rem;
  min-height: 25px;

  .dot-wrapper {
    width: 25px;
    height: 100%;
    cursor: pointer;
  }

  .dot {
    border: solid 2px var(--primary-color);
    border-radius: 50%;
    position: relative;

    div {
      background-color: var(--primary-color);
      border-radius: 50%;
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
    }
  }
}
