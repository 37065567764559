section#landing {
  position: relative;
  isolation: isolate;

  .section-background {
    position: absolute;
    inset: 0;
    z-index: -1;

    .bg-image {
      width: 100%;
      height: 100vh;
      background-image: url("../../../assets/webp/landing-bg.webp");
      background-attachment: fixed;
      background-size: cover;
      background-repeat: no-repeat;
      background-position-x: center;
      background-position-y: 60%;

      @supports (-webkit-touch-callout: none) {
        background-attachment: scroll;
      }
    }

    .bg-gradient {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: radial-gradient(
        50% 50% at 50% 50%,
        rgba(30, 30, 30, 0.4) 0%,
        rgba(30, 30, 30, 0.9) 94.65%
      );
    }
  }

  main.content {
    width: 100%;
    height: 100%;
    gap: 4rem;
    margin-top: 50%;

    @include md {
      margin-top: 25%;
    }

    @include lg {
      margin-top: 15%;
    }

    .text-content {
      gap: 1rem;

      header {
        font-family: "Oswald", sans-serif;
        color: #fff;
        font-size: 2.25em;
        line-height: 2.5rem;
        text-align: center;

        @include lg {
          max-width: 1000px;
          font-size: 3em;
          line-height: 4.5rem;
        }
      }

      p {
        color: #fff;
        font-size: 0.9em;
        font-weight: $fw-light;
        text-align: center;
      }
    }
  }
}

section#o-nas {
  padding-top: 8rem;
  min-height: 90vh;

  main.content {
    gap: 3rem;

    .content-wrapper {
      gap: 3rem;
      width: 100%;

      @include md {
        flex-direction: row;
        gap: 6rem;
      }

      @include lg {
        gap: 30vw;
      }

      .company-info {
        gap: 1.25rem;
        max-width: 500px;

        p {
          font-weight: $fw-light;
          line-height: 1.3rem;
        }

        .button-holder {
          margin-top: 1rem;
        }
      }

      .stats-counters {
        gap: 1rem;

        @include md {
          flex-direction: column;
          align-items: flex-end;
        }

        .stats-box {
          max-width: 160px;

          .number-counter {
            font-size: 3.5em;
            font-weight: $fw-bold;
            color: var(--primary-color);
          }

          .stats-title {
            font-weight: $fw-light;
            font-size: 0.9em;
          }
        }
      }
    }
  }
}

section#our-work {
  padding: 0;
  position: relative;

  main.content {
    height: 100%;
    padding: var(--site-padding);
    padding-top: 6rem;
    gap: 2rem;

    @include lg {
      flex-direction: row;
      padding: 0;
    }

    .button-holder {
      @include lg {
        position: absolute;
        left: 50%;
        bottom: 2rem;
        transform: translateX(-50%);
      }
    }

    .fade-wrapper {
      transition: 300ms all ease;

      @include lg {
        &[data-fade="true"] {
          opacity: 0;
        }
      }
    }

    .header-wrapper {
      @include lg {
        position: absolute;
        left: var(--site-padding-x);
        top: 40%;
        transform: translateY(-50%);

        align-items: flex-start;

        header,
        span {
          text-align: left;
        }
      }
    }

    .work-carousel {
      overflow: hidden;
      cursor: grab;
      width: 100vw;
      position: relative;

      @include lg {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
      }

      .inner-work-carousel {
        gap: 4rem;

        .item {
          width: 100%;
          height: 100%;
          display: flex;
          min-width: 350px;
          min-height: 450px;
          pointer-events: none;
          user-select: none;

          @include lg {
            min-height: 700px;
          }

          background-repeat: no-repeat;
          background-size: cover;
          transition: 200ms all ease-in-out;
          background-position: center;
          opacity: 0.8;
          border-radius: 5px;

          &[data-bg-pos="left"] {
            background-position-x: left;
            transform: scale(0.85);
          }

          &[data-bg-pos="center"] {
            background-position-x: center;
            opacity: 1;
          }

          &[data-bg-pos="right"] {
            background-position-x: right;
            transform: scale(0.85);
          }
        }
      }

      .carousel-nav {
        @include lg {
          display: none;
        }

        .carousel-nav-left,
        .carousel-nav-right {
          padding: 0.25rem;
          background-color: rgba(0, 0, 0, 0.5);
          position: absolute;
          width: 36px;
          height: 36px;
          border-radius: 50%;
          display: flex;
          align-items: center;
          justify-content: center;
          top: 50%;
          transform: translateY(-50%);

          svg path {
            stroke: #fff;
            stroke-width: 2px;
          }
        }

        .carousel-nav-left {
          left: 0.5rem;

          svg {
            transform: rotate(90deg);
          }
        }

        .carousel-nav-right {
          right: 0.5rem;

          svg {
            transform: rotate(-90deg);
          }
        }
      }
    }
  }
}

section#offer {
  margin-top: 10vh;
  position: relative;
  isolation: isolate;

  .dots-illustration {
    position: absolute;
    right: -30%;
    top: 20%;
    z-index: -1;

    @include lg {
      top: -20%;
      right: 0;
    }
  }

  main.content {
    width: 100%;
    height: 100%;
    gap: 8rem;

    .offer-items-columns {
      width: 100%;
      align-items: flex-start;
      gap: 2rem;
      height: 100%;
      flex-direction: column;

      @include lg {
        flex-direction: row;
      }

      .offer-items-column,
      .offer-image-column {
        width: 100%;
      }

      .offer-items-column {
        padding: 20vh 0 10vh;

        @include lg {
          padding: 50vh 0;
        }

        ul {
          list-style: none;
        }
      }

      .offer-image-column {
        display: flex;
        align-items: center;
        align-self: flex-start;
        justify-content: flex-start;
        position: sticky;
        bottom: 1rem;
        right: 0;

        @include lg {
          height: 100vh;
          top: 0;
          bottom: initial;
          right: initial;
        }

        .offer-image-wrapper {
          width: 100%;
          aspect-ratio: 3 / 2;
          background-color: lightgray;
          border-radius: 10px;
          position: relative;
          max-height: 30vh;

          @include lg {
            aspect-ratio: 1 / 1;
            max-height: 500px;
          }
        }
      }
    }
  }
}

section#reviews {
  position: relative;
  isolation: isolate;

  .dots-illustration {
    position: absolute;
    left: 0;
    top: -10%;
    z-index: -1;

    @include lg {
      top: 0;
      left: 10%;
    }
  }

  main.content {
    gap: 5rem;

    .reviews {
      width: 100%;
      height: 100%;
      gap: 1rem;

      .reviews-row {
        gap: 1rem;
        height: 100%;
        width: 100%;

        .row {
          flex-shrink: 0;
          display: flex;
          align-items: center;
          gap: 1rem;
        }
      }
    }
  }
}

section#oferteo {
  display: flex;
  align-items: center;
  min-height: 80vh;

  main.content {
    width: 100%;
    height: 100%;

    .oferteo-box {
      height: 100%;
      gap: 2rem;

      img {
        width: 128px;
        height: 128px;
      }

      p {
        font-weight: $fw-light;
        max-width: 350px;
        text-align: center;
      }
    }
  }
}
