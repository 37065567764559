.input-wrapper.password {
  .input-wrapper-content {
    width: 100%;
    position: relative;

    input:not(:placeholder-shown) + .eye-icon {
      display: flex;
    }

    .eye-icon {
      display: none;
      position: absolute;
      z-index: 2;
      right: 1rem;
      top: 50%;
      transform: translateY(-50%);
      align-items: center;
      cursor: pointer;
    }
  }
}
